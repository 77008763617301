body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loaderFixed {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999999999999999999999999999999999999999999999 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1c273a;
}

:root {
  --header-height: 70px;
  --logo: 40px;
  --main-color: #431667;
  --main-color-darken: #381053;
  --special-color: #ff003b;
  --special-color-darken: #dc0038;
}
@media (max-width: 991px) {
  :root {
    --header-height: 60px;
  }
}

* {
  letter-spacing: 0.5px;
  text-decoration: none !important;
  font-family: "Nunito", sans-serif;
}

.main-wrap {
  margin-top: var(--header-height);
  min-height: calc(100vh - (var(--header-height) + 85px));
}

/*====================================================================================================================*/
/* search-dropdown */
/*====================================================================================================================*/
.search-dropdown {
  margin-top: 50px;
  z-index: 999999;
}
.search-dropdown .search-delete {
  /*margin-top: 7.5px;
  margin-right: 7px;*/
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.search-dropdown .search-delete:hover {
  background-color: #8824d9;
  color: #fff !important;
}

/*====================================================================================================================*/
/* Main Header */
/*====================================================================================================================*/
.main-pages {
  background-image: url("/assets/image/Rectangle 140.png");
  background-size: cover;
  background-position: bottom;
}
.main-pages .main-title-2::before {
  content: "";
  position: absolute;
  background-image: url(/assets/image/Elipse.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 150px;
  height: 80px;
  top: -40px;
  left: -50px;
}

/*====================================================================================================================*/
/* Main Header */
/*====================================================================================================================*/
.main-header {
  height: var(--header-height);
  z-index: 999999999999999999999 !important;
}
.main-header .logo img {
  width: auto;
  height: var(--logo);
}
@media (min-width: 992px) {
  .main-header .logo::before {
    content: "";
    position: absolute;
    top: calc((var(--header-height) / 2) - 20px);
    right: 0;
    margin-right: -30px;
    width: 2px;
    height: 20px;
    background: #ddd;
  }
}
.main-header .language .language-link {
  width: 30px;
  height: 25px;
}
.main-header .language .language-link.active {
  background: linear-gradient(to left, #431667, #8824d9);
  color: #fff !important;
}

/*====================================================================================================================*/
/* header */
/*====================================================================================================================*/
.navbar {
  position: fixed !important;
  top: 0;
  box-shadow: 0 0 15px #b1b1b159;
  height: var(--header-height) !important;
}
.navbar .main-navber .nav-link {
  padding: 0 15px;
}
@media (max-width: 991px) {
  .navbar .main-navber .nav-link {
    padding: 15px !important;
  }
}
.navbar .main-navber .active {
  background: linear-gradient(to left, #561b82, #7720be);
  color: #fff !important;
}
@media (max-width: 991px) {
  .navbar {
    padding-left: calc(var(--header-height) - 40px) !important;
    padding-right: calc(var(--header-height) - 40px) !important;
  }
  .navbar .main-navber {
    position: fixed;
    z-index: -1;
    background-color: #ffffff;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    left: 0;
    right: 0;
    text-align: center;
    width: 100%;
  }
  .navbar .main-navber.show,
  .navbar .main-navber.collapsing {
    top: var(--header-height);
  }
  .navbar .navbar-toggler {
    border: 0;
    outline: none;
  }
}
.navbar .dropdown-menu {
  min-width: 50px !important;
  text-align: center;
  border-radius: 0 !important;
  border-top: 3px solid #00abbd;
  border-bottom: 3px solid #00abbd;
}
.navbar .dropdown-menu .dropdown-item {
  text-align: center;
}

/*====================================================================================================================*/
/* Footer :) */
/*====================================================================================================================*/
footer {
  background-image: url("/assets/image/Rectangle 34.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

/*====================================================================================================================*/
/* col-max-400 */
/*====================================================================================================================*/
@media (max-width: 400px) {
  .col-max-400 .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/*====================================================================================================================*/
/* Color */
/*====================================================================================================================*/
.text-main {
  color: var(--main-color);
}
.empty-star {
  color: #cccc;
}

.bg-main {
  background: linear-gradient(to left, #561b82, #7720be);
}

/*====================================================================================================================*/
/* Card left color */
/*====================================================================================================================*/
.card-left-one {
  border-left: 3px solid #a155ed;
}

.card-left-two {
  border-left: 3px solid #ed5555;
}

.card-left-three {
  border-left: 3px solid #5bd759;
}

.card-left-four {
  border-left: 3px solid #ff8a35;
}

.card-left-five {
  border-left: 3px solid #53c1ff;
}

/*====================================================================================================================*/
/* Card top color */
/*====================================================================================================================*/
.card-top-one {
  border-top: 3px solid #a155ed;
}

.card-top-two {
  border-top: 3px solid #ed5555;
}

.card-top-three {
  border-top: 3px solid #5bd759;
}

.card-top-four {
  border-top: 3px solid #ff8a35;
}

.card-top-five {
  border-top: 3px solid #53c1ff;
}

.card-top-main {
  border-top: 3px solid var(--main-color);
}

/*====================================================================================================================*/
/* Card bottom color */
/*====================================================================================================================*/
.card-bottom-one {
  border-bottom: 3px solid #a155ed;
}

.card-bottom-two {
  border-bottom: 3px solid #ed5555;
}

.card-bottom-three {
  border-bottom: 3px solid #5bd759;
}

.card-bottom-four {
  border-bottom: 3px solid #ff8a35;
}

.card-bottom-five {
  border-bottom: 3px solid #53c1ff;
}

.card-bottom-main {
  border-bottom: 3px solid var(--main-color);
}

/*====================================================================================================================*/
/* Font sizes */
/*====================================================================================================================*/
.font-8 {
  font-size: 8px;
}

.font-9 {
  font-size: 9px;
}

.font-10 {
  font-size: 10px;
}

.font-11 {
  font-size: 11px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
}

.font-17 {
  font-size: 17px;
}

.font-18 {
  font-size: 18px;
}

.font-19 {
  font-size: 19px;
}

.font-20 {
  font-size: 20px;
}

.font-21 {
  font-size: 21px;
}

.font-22 {
  font-size: 22px;
}

.font-23 {
  font-size: 23px;
}

.font-24 {
  font-size: 24px;
}

.font-25 {
  font-size: 25px;
}

.font-30 {
  font-size: 30px;
}

.font-35 {
  font-size: 35px;
}

.font-40 {
  font-size: 40px;
}

.font-45 {
  font-size: 45px;
}

.font-50 {
  font-size: 50px;
}

.font-55 {
  font-size: 55px;
}

.font-60 {
  font-size: 60px;
}

.font-65 {
  font-size: 65px;
}

@media (max-width: 575px) {
  .font-xs-10 {
    font-size: 10px !important;
  }

  .font-xs-11 {
    font-size: 11px !important;
  }

  .font-xs-12 {
    font-size: 12px !important;
  }

  .font-xs-13 {
    font-size: 13px !important;
  }

  .font-xs-14 {
    font-size: 14px !important;
  }

  .font-xs-15 {
    font-size: 15px !important;
  }

  .font-xs-16 {
    font-size: 16px !important;
  }

  .font-xs-17 {
    font-size: 17px !important;
  }

  .font-xs-18 {
    font-size: 18px !important;
  }

  .font-xs-19 {
    font-size: 19px !important;
  }

  .font-xs-20 {
    font-size: 20px !important;
  }

  .font-xs-21 {
    font-size: 21px !important;
  }

  .font-xs-22 {
    font-size: 22px !important;
  }

  .font-xs-23 {
    font-size: 23px !important;
  }

  .font-xs-24 {
    font-size: 24px !important;
  }

  .font-xs-25 {
    font-size: 25px !important;
  }

  .font-xs-30 {
    font-size: 30px !important;
  }

  .font-xs-35 {
    font-size: 35px !important;
  }

  .font-xs-40 {
    font-size: 40px !important;
  }

  .font-xs-45 {
    font-size: 45px !important;
  }

  .font-xs-50 {
    font-size: 50px !important;
  }

  .font-xs-55 {
    font-size: 55px !important;
  }

  .font-xs-60 {
    font-size: 60px !important;
  }

  .font-xs-65 {
    font-size: 65px !important;
  }
}
@media (min-width: 576px) {
  .font-sm-10 {
    font-size: 10px !important;
  }

  .font-sm-11 {
    font-size: 11px !important;
  }

  .font-sm-12 {
    font-size: 12px !important;
  }

  .font-sm-13 {
    font-size: 13px !important;
  }

  .font-sm-14 {
    font-size: 14px !important;
  }

  .font-sm-15 {
    font-size: 15px !important;
  }

  .font-sm-16 {
    font-size: 16px !important;
  }

  .font-sm-17 {
    font-size: 17px !important;
  }

  .font-sm-18 {
    font-size: 18px !important;
  }

  .font-sm-19 {
    font-size: 19px !important;
  }

  .font-sm-20 {
    font-size: 20px !important;
  }

  .font-sm-21 {
    font-size: 21px !important;
  }

  .font-sm-22 {
    font-size: 22px !important;
  }

  .font-sm-23 {
    font-size: 23px !important;
  }

  .font-sm-24 {
    font-size: 24px !important;
  }

  .font-sm-25 {
    font-size: 25px !important;
  }

  .font-sm-30 {
    font-size: 30px !important;
  }

  .font-sm-35 {
    font-size: 35px !important;
  }

  .font-sm-40 {
    font-size: 40px !important;
  }

  .font-sm-45 {
    font-size: 45px !important;
  }

  .font-sm-50 {
    font-size: 50px !important;
  }

  .font-sm-55 {
    font-size: 55px !important;
  }

  .font-sm-60 {
    font-size: 60px !important;
  }

  .font-sm-65 {
    font-size: 65px !important;
  }
}
@media (min-width: 768px) {
  .font-md-10 {
    font-size: 10px !important;
  }

  .font-md-11 {
    font-size: 11px !important;
  }

  .font-md-12 {
    font-size: 12px !important;
  }

  .font-md-13 {
    font-size: 13px !important;
  }

  .font-md-14 {
    font-size: 14px !important;
  }

  .font-md-15 {
    font-size: 15px !important;
  }

  .font-md-16 {
    font-size: 16px !important;
  }

  .font-md-17 {
    font-size: 17px !important;
  }

  .font-md-18 {
    font-size: 18px !important;
  }

  .font-md-19 {
    font-size: 19px !important;
  }

  .font-md-20 {
    font-size: 20px !important;
  }

  .font-md-21 {
    font-size: 21px !important;
  }

  .font-md-22 {
    font-size: 22px !important;
  }

  .font-md-23 {
    font-size: 23px !important;
  }

  .font-md-24 {
    font-size: 24px !important;
  }

  .font-md-25 {
    font-size: 25px !important;
  }

  .font-md-30 {
    font-size: 30px !important;
  }

  .font-md-35 {
    font-size: 35px !important;
  }

  .font-md-40 {
    font-size: 40px !important;
  }

  .font-md-45 {
    font-size: 45px !important;
  }

  .font-md-50 {
    font-size: 50px !important;
  }

  .font-md-55 {
    font-size: 55px !important;
  }

  .font-md-60 {
    font-size: 60px !important;
  }

  .font-md-65 {
    font-size: 65px !important;
  }
}
@media (min-width: 992px) {
  .font-lg-10 {
    font-size: 10px !important;
  }

  .font-lg-11 {
    font-size: 11px !important;
  }

  .font-lg-12 {
    font-size: 12px !important;
  }

  .font-lg-13 {
    font-size: 13px !important;
  }

  .font-lg-14 {
    font-size: 14px !important;
  }

  .font-lg-15 {
    font-size: 15px !important;
  }

  .font-lg-16 {
    font-size: 16px !important;
  }

  .font-lg-17 {
    font-size: 17px !important;
  }

  .font-lg-18 {
    font-size: 18px !important;
  }

  .font-lg-19 {
    font-size: 19px !important;
  }

  .font-lg-20 {
    font-size: 20px !important;
  }

  .font-lg-21 {
    font-size: 21px !important;
  }

  .font-lg-22 {
    font-size: 22px !important;
  }

  .font-lg-23 {
    font-size: 23px !important;
  }

  .font-lg-24 {
    font-size: 24px !important;
  }

  .font-lg-25 {
    font-size: 25px !important;
  }

  .font-lg-30 {
    font-size: 30px !important;
  }

  .font-lg-35 {
    font-size: 35px !important;
  }

  .font-lg-40 {
    font-size: 40px !important;
  }

  .font-lg-45 {
    font-size: 45px !important;
  }

  .font-lg-50 {
    font-size: 50px !important;
  }

  .font-lg-55 {
    font-size: 55px !important;
  }

  .font-lg-60 {
    font-size: 60px !important;
  }

  .font-lg-65 {
    font-size: 65px !important;
  }
}
/*====================================================================================================================*/
/* card menu :) */
/*====================================================================================================================*/
.card .collapsed {
  color: var(--main-color);
}
.card .collapsed .active-up {
  transform: rotate(180deg);
}
.card .btn-link:hover {
  color: var(--main-color);
  text-decoration: underline;
}

/*====================================================================================================================*/
/* list menu :) */
/*====================================================================================================================*/
.listmenu-filter {
  position: relative !important;
  display: flex !important;
  flex-flow: nowrap !important;
  overflow-x: scroll !important;
}
.listmenu-filter::-webkit-scrollbar {
  height: 0 !important;
}
.listmenu-filter a {
  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
@media (max-width: 991px) {
  .listmenu-filter a {
    min-width: 130px;
  }
}
.listmenu-filter a.list-menu-link {
  min-width: 67px !important;
}
.listmenu-filter .list-group-item.active {
  background: linear-gradient(to left, #431667, #8824d9) !important;
}

/*====================================================================================================================*/
/* Mix code :) */
/*====================================================================================================================*/
.vertical-align-inherit {
  vertical-align: inherit !important;
}

.mdi {
  line-height: initial;
  vertical-align: middle;
}

.overflow-visible {
  overflow: visible;
}

.overflow-x-auto {
  overflow: auto !important;
}

.focus-none:focus {
  outline: none !important;
  box-shadow: none !important;
}

.flex-none {
  flex: none !important;
}

.bg-gray {
  background: #ddd;
}

.bg-gray-2 {
  background: #f4f4f4;
}

img.contain {
  object-fit: contain;
}
img.cover {
  object-fit: cover;
}
img.fill {
  object-fit: fill;
}

.flex-flow-nowrap {
  flex-flow: nowrap;
}

.input-active .input-icon {
  position: absolute;
  right: 0;
  top: 0;
  width: 42px;
  line-height: 41px;
  text-align: center;
  color: #ccc !important;
  z-index: 9;
  text-shadow: none;
}
.input-active .input-icon .mdi {
  background: #8824d9;
  display: inline-flex;
  width: 35px !important;
  height: 35px !important;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.radius-10 {
  border-radius: 10px;
}

.radius-15 {
  border-radius: 15px;
}

.radius-20 {
  border-radius: 20px;
}

.shadow-big {
  box-shadow: 0 0 15px #626065b3;
}

.tr-3s {
  transition: all 0.3s;
}

.bg-icon {
  overflow: hidden;
}
.bg-icon .mdi {
  background: linear-gradient(to left, #431667, #8824d9);
  display: inline-flex;
  width: 35px !important;
  height: 35px !important;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.rotate-90 {
  transform: rotate(90deg);
}

.item img {
  transition: all 0.3s;
}
.item :hover img {
  transform: scale(1.1);
}

.list-btn {
  position: relative;
  display: inline-block;
}
@media (min-width: 1255px) {
  .list-btn {
    position: absolute;
    margin-left: -65px;
  }
}
.list-btn .list-btn-number {
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  right: 0;
  top: 0;
  margin-top: -7px;
  margin-right: -7px;
}

.pointer {
  cursor: pointer;
}

.section-one {
  background-image: url("/assets/image/Background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
@media (min-width: 992px) {
  .section-one {
    min-height: calc(100vh - (var(--header-height) + 110px));
  }
}
.section-one .main-title::before {
  content: "";
  position: absolute;
  background-image: url("/assets/image/Elipse.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 250px;
  height: 150px;
  top: -15px;
  left: 20%;
}

.section-two .thumb {
  height: 40px;
}
.section-two .thumb img {
  max-height: -webkit-fill-available;
}

.contact-button {
  transition: all 0.3s;
  /*.bg-icon{
      margin-right: auto !important;
  }*/
}
.contact-button:hover > div {
  transition: all 0.3s;
  background: linear-gradient(to left, #431667, #8824d9);
}
.contact-button:hover > div .bg-icon {
  transition: all 0.3s;
}
.contact-button:hover > div .bg-icon .mdi {
  transition: all 0.3s;
  background: #fff !important;
  color: #8824d9;
}
.contact-button:hover > div span {
  transition: all 0.3s;
  color: #fff !important;
}

/*====================================================================================================================*/
/*====================================================================================================================*/
.form-control {
  height: calc(1.5em + 1rem + 2px) !important;
}

img {
  max-width: 100% !important
  ;
}

#navbarSupportedContent.in,
#navbarSupportedContent.collapsing {
  display: block !important;
}

/* pagination */
.pagination .page-link {
  min-width: 36px;
  text-align: center;
}
.pagination .active .page-link {
  background-color: var(--main-color) !important;
  color: #fff !important;
}
.pagination .disabled .page-link {
  opacity: 0.8 !important;
  box-shadow: none !important;
}

/* my gutters */
.my-gutters {
  margin-left: -10px;
  margin-right: -10px;
}
@media only screen and (max-width: 994px) {
  .my-gutters {
    margin-left: -5px;
    margin-right: -5px;
  }
}

.p-20 {
  padding: 20px;
}

.fl {
  float: left;
  background-color: #8724d7;
  margin-right: 5px;
  width: 25px;
  text-align: center;
  border-radius: 15px;
  font-size: 15px;
  color: white;
}
.special-chars {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: -50px;
}

.bottom-20 {
  margin-bottom: 20px;
}

.my-danger {
  float: left;
  font-size: 14px;
  font-weight: bold;
}
.bold {
  font-weight: bold;
}

.my-toast {
  transition: all 0.3s ease;
}
.hide-toast {
  opacity: 0;
  margin-bottom: -20px;
  height: 0px !important;
}

.active-result {
  border: 2px solid #afb8c7;
}

.sticky-banner {
  position: sticky;
  top: 70px;
}

@media only screen and (max-width: 992px) {
  .d-none {
    display: none;
  }
}

/*# sourceMappingURL=main.css.map */
